<template>
  <v-footer
    class="py-0 my-0"
    height="auto"
  >
    <v-container class="py-1 mx-auto">
      <div class="grey--text text-caption d-flex align-end flex-column">
        Tried {{ count.totalTry }} | Today {{ count.todayVisit }} | Total
        {{ count.totalVisit }}
      </div>
      <!-- <v-row>
        <v-col cols="9">
          <base-btn
            v-for="(item, i) in items"
            :key="i"
            :href="item.href"
            class="ml-0 mr-3"
            color="primary"
            square
            target="_blank"
          >
            <v-icon v-text="item.icon" />
          </base-btn>
          try-me
        </v-col>
        <v-spacer />

        <v-col>
          <base-btn
            class="mr-0"
            square
            title="Go to top"
            @click="$vuetify.goTo(0)"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </base-btn>
        </v-col>
      </v-row> -->
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',

  data: () => ({
    items: [
      {
        href: '#!',
        icon: 'mdi-twitter',
      },

      {
        href: 'https://trivue.dev',
        icon: 'mdi-vuejs',
      },
    ],
    count: [],
  }),
  async created() {
    await this.$store.dispatch('count/fetchCount')
    this.count = await this.$store.getters['count/getAllCount']
  },
}
</script>
